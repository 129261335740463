<template>
  <div>
    <div class="course-card">
      <div v-if="type == 'homeCourse'">
        <div class="course-card_top course_center">
          <span class="course-card_top_school">{{ course.school }}</span>
          <span class="course-card_top_date" v-if="course.places < 3 && course.lesson_status == 1"
            @click="playBtn('changeDate')">{{ lang.btn_changeDate }}</span>
          <span class="course-card_top_date" v-if="course.lesson_status != 1" @click="playBtn('lookcheckIn')">{{
            lang.btn_looksignIn }}</span>
        </div>
        <div class="course-card_coursetitle">
          {{ language == "en" ? course.course_name_en : course.course_name_cn }}
        </div>
      </div>
      <div v-if="type == 'courseList'">
        <div class="course-card_top course_center courseall-card_top">
          <span class="course-card_top_school">{{ course.school }}</span>
          <span class="course-card_top_school">{{ course.lesson_date }}</span>
        </div>
        <div class="course_center">
          <span class="course-card_coursetitle">
            {{
              language == "en" ? course.course_name_en : course.course_name_cn
            }}
          </span>
          <span class="course-card_top_date" v-if="course.places < 3 && course.lesson_status == 1"
            @click="playBtn('changeDate')">{{ lang.btn_changeDate }}</span>
          <span class="course-card_top_date" v-if="course.lesson_status != 1" @click="playBtn('lookcheckIn')">{{
            lang.btn_looksignIn }}</span>
        </div>
      </div>
      <div class="course-card_number">
        <span :style="`width:${language == 'en' ? '100%' : 'auto'} ;`">{{ lang.formLabel_courseProgress }}：{{
          course.lesson_used }}/{{
    course.lesson_total
  }}</span>
        <span :style="`width:${language == 'en' ? '100%' : 'auto'} ;`">{{ lang.formLabel_student }}：{{ course.student_real
        }}/{{
  course.student_total
}}</span>
      </div>
      <div class="course-card_info">
        <div>
          <span class="course-card_info_time">{{ lang.formLabel_time }}：{{ course.start_time.substr(0, 5) }} -
            {{ course.end_time.substr(0, 5) }}</span>
          <span class="course-card_info_address">{{ lang.formLabel_place }}：{{ course.location }}</span>
        </div>
        <van-button round plain type="primary" v-if="course.lesson_status == 1 && contrastDate() != 'future'"
          @click="playBtn('checkIn')">{{
            contrastDate() == "now"
            ? lang.btn_signIn
            : lang.btn_complementSigned
          }}</van-button>
        <van-button round plain type="primary" v-if="course.lesson_status == 2" @click="playBtn('comment')">{{
          lang.btn_review }}</van-button>
        <van-button class="hasreview" round plain type="primary" v-if="course.lesson_status == 3"
          @click="playBtn('comment')">{{ `${lang.btn_reviewed}${course.places == 1 ? '' : '(' + course.personal_feedback + ')'}`
          }}</van-button>
      </div>
    </div>
    <MonthsCalendar ref="MonthsCalendar" @changeDate="changeDate" :lang="lang" />
  </div>
</template>
  
<script>
import { reactive, toRefs, onMounted, ref } from "vue";
import Common from "@/library/Common";
import { useRouter } from "vue-router";
import MonthsCalendar from "@/components/course/calendar/months";
import request from "@/library/MyRequest";
import { Toast } from "vant";
export default {
  name: "ClassEvaluation",
  components: {
    MonthsCalendar,
  },
  props: {
    type: String,
    course: Object,
  },
  setup(props, context) {
    const MonthsCalendar = ref(null);
    const common = Common;
    const router = useRouter();
    const currentPath = router.currentRoute.value.path;
    let state = reactive({
      showPopup: false,
      type: props.type,
      lang: {
        route_home: "",
        langBtn: "",
        formLabel_courseProgress: "",
        formLabel_student: "",
        formLabel_time: "",
        formLabel_place: "",
        btn_review: "",
        btn_reviewed: "",
        btn_signIn: "",
        btn_complementSigned: "",
        btn_changeDate: "",
        determine: "",
        btn_looksignIn: "",
        btn_complementSigned: "",
        submit_type_success: "",
      },
      course: props.course,
      language: common.getLocal("Language"),
    });
    console.log('course', state.course);
    common.initLanguage(state.lang);
    const playBtn = (btnType) => {
      switch (btnType) {
        case "changeDate":
          MonthsCalendar.value.initPopup(state.course.course_id);
          break;
        case "checkIn":
          router.push({
            path: `/checkin`,
            query: {
              courseData: JSON.stringify({
                currentPath: currentPath,
                courseId: state.course.lesson_id,
                courseName:
                  state.language == "en"
                    ? state.course.course_name_en
                    : state.course.course_name_cn,
                lesson_status: state.course.lesson_status,
                courseType: state.course.courseType,
              }),
            },
          });
          break;
        case "lookcheckIn":
          router.push({
            path: `/checkin`,
            query: {
              courseData: JSON.stringify({
                currentPath: currentPath,
                courseId: state.course.lesson_id,
                courseName:
                  state.language == "en"
                    ? state.course.course_name_en
                    : state.course.course_name_cn,
                lesson_status: state.course.lesson_status,
                isLook: true,
                courseType: state.course.courseType,
              }),
            },
          });
          break;
        case "comment":
          router.push({
            path: `/comment/student`,
            query: {
              courseData: JSON.stringify({
                courseId: state.course.lesson_id,
                currentPath: currentPath,
              }),
            },
          });
          break;
        case "miss":
          router.push({
            path: `/misslesson`,
            query: {
              courseData: JSON.stringify({
                courseId: state.course.lesson_id,
              }),
            },
          });
          break;
      }
    };
    const contrastDate = () => {
      let curDate = new Date();
      let curYear = curDate.getFullYear();
      let curMonth = curDate.getMonth() + 1;
      let curDay = curDate.getDate();
      let nowDate = new Date(
        `${curYear}-${curMonth < 10 ? "0" + curMonth : curMonth}-${curDay < 10 ? "0" + curDay : curDay
        }`
      ).getTime();
      let courseDate = new Date(state.course.lesson_date).getTime();
      let courseType = "";
      if (nowDate == courseDate) {
        courseType = "now";
      }
      if (nowDate > courseDate) {
        courseType = "isGone";
      }
      if (nowDate < courseDate) {
        courseType = "future";
      }
      state.course["courseType"] = courseType;
      return courseType;
    };
    const changeDate = async (date) => {
      console.log("changeDate", date, state.course);
      const courseDate = state.course.lesson_date;
      let data = new FormData();
      data.append("date", date);
      data.append("lesson_id", state.course.lesson_id);
      var res = await request.put(`lesson/change-date/`, data);
      console.log("changeDate", res);
      if (props.type == "homeCourse") {
        context.emit("refreshData", courseDate);
      } else {
        context.emit("onLoad");
        // state.course.lesson_date = date;
      }
      Toast({
        forbidClick: true,
        message: state.lang.submit_type_success,
      });
    };
    onMounted(() => {
      window.addEventListener("setItemEvent", function (e) {
        if (e.key === "Language") {
          setTimeout(() => {
            common.initLanguage(state.lang);
            state.language = common.getLocal("Language");
          }, 300);
        }
      });
    });
    return {
      ...toRefs(state),
      common,
      playBtn,
      changeDate,
      contrastDate,
      MonthsCalendar,
    };
  },
};
</script>
  
<style lang="less" scoped>
.hasreview {
  color: #a5907e;
  border-color: #a5907e;
}

/deep/.van-popup {
  overflow-y: hidden;
}

/deep/.van-button {
  min-width: 196px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 24px;
}

.course-card {
  text-align: left;
  padding: 20px;
  background-color: #fff;
  font-size: 28px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 40px;
  margin: 20px;
  border-radius: 8px;

  .course_center {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .course-card_top {
    margin-bottom: 8px;
  }

  .courseall-card_top {
    padding-bottom: 20px;
    border-bottom: 1px solid #ececec;
  }

  .course-card_top_school {
    color: #999999;
    font-size: 26px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    line-height: 36px;
  }

  .course-card_top_date {
    color: #a5907e;
    font-size: 26px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    line-height: 36px;
    white-space: nowrap;
  }

  .course-card_coursetitle {
    font-size: 34px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: 48px;
    margin-bottom: 10px;
  }

  .course-card_number {

    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    span {
      display: inline-block;
      margin-bottom: 10px;
      white-space: nowrap;

      &:nth-child(1) {
        margin-right: 80px;
      }
    }
  }

  .course-card_info {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    span {
      display: block;
      margin-bottom: 8px;
    }
  }
}
</style>