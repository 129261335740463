export const week = [{
        text: "日",
        value: "",
    },
    {
        text: "一",
        value: "",
    },
    {
        text: "二",
        value: "",
    },
    {
        text: "三",
        value: "",
    },
    {
        text: "四",
        value: "",
    },
    {
        text: "五",
        value: "",
    },
    {
        text: "六",
        value: "",
    },
]
export const types = [{
        text: "status_course",
        color: "#9CD1A0"
    },
    {
        text: "status_SignInReview",
        color: "#EAA983"
    },
    {
        text: "status_reviewed",
        color: "#DDCAB0"
    },
]
export const statusColor = {
    1: "#9CD1A0",
    2: "#EAA983",
    3: "#DDCAB0",
    // 4: "rabg(0,0,0,0)",
    4: "rgba(156, 209, 160, 0.6)",
}