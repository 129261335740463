<template>
  <van-popup
    round
    safe-area-inset-bottom
    :show="showPopup"
    position="bottom"
    :style="{ height: '90%' }"
  >
    <div class="calendar">
      <div>
        <div class="calendar_top">
          <span class="calendar_top_title">{{ lang.btn_changeDate }}</span>
          <van-icon name="cross" size="26" @click="cancel" />
        </div>
        <!-- 周期 -->
        <div class="calendar_week">
          <span v-for="(item, index) in week" :key="index">{{
            item.text
          }}</span>
        </div>
      </div>
      <div class="calendar_days" ref="scrollMonth" @scroll="handleScroll">
        <div
          class="monthcard"
          v-for="(item, index) in months"
          :key="index"
          :ref="item.id"
          :id="item.id"
        >
          <div class="calendar_days_month">{{ item.dateMonth }}</div>
          <div class="calendar_days_list">
            <div
              class="calendar_days_item"
              v-for="(day, d) in item.days"
              :key="d"
              :style="`margin-left:${day.left}%`"
            >
              <span
                class="calendar_days_item_num"
                @click="selectCurrentDay(day)"
                :style="`background:${
                  currentSelectDay == day.date ? '#9CD1A0' : '#ffffff'
                };color:${
                  currentSelectDay == day.date
                    ? '#ffffff'
                    : day.isGone
                    ? 'rgb(153, 153, 153,0.5)'
                    : '#000000'
                }`"
                >{{ day.day }}</span
              >
              <span
                class="calendar_days_item_icondrop"
                v-if="day.status != -1 && currentSelectDay != day.date"
                :style="`background:${
                  day.status == 4 ? '#ffffff' : '#9CD1A0'
                };`"
              ></span>
            </div>
          </div>
        </div>
      </div>
      <div class="footer_btn">
        <van-button type="primary" @click="changeDate">{{
          lang.determine
        }}</van-button>
      </div>
    </div>
  </van-popup>
</template>

<script>
import { week, types, statusColor } from "./const";
import { getMonthDays, getWeekNum, setMonthDays } from "./calendar";
import request from "@/library/MyRequest";
export default {
  props: {
    lang: Object,
  },
  data() {
    return {
      showPopup: false,
      week: week,
      types: types,
      statusColor: statusColor,
      currentSelectDay: null,
      months: [], //月份的天数
      date: {
        year: 0, //年
        month: 0, //月份
        // day: 0, //日
      },
      course_id: "",
      lessonsList: [],
      isok: true,
    };
  },
  created() {
    this.handleScroll = this.debounce(this.scrollMonth, 500);
  },
  mounted() {
    // this.$nextTick(() => {
    //   console.log("clientHeight", this.$refs["scrollMonth"]);
    //   this.$refs["scrollMonth"].scrollTo(0, 300);
    // });
  },
  methods: {
    async scrollMonth(e) {
      const { scrollTop, clientHeight, scrollHeight } = e.target;
      console.log(
        "handleScroll",
        scrollTop,
        clientHeight,
        scrollTop + clientHeight,
        scrollHeight
      );
      if (scrollHeight - (scrollTop + clientHeight) < 100) {
        let lastMonth = this.months[this.months.length - 1];
        let { data } = lastMonth;
        await this.setMonth(
          data["month"] == 12 ? data["year"] + 1 : data["year"],
          data["month"] == 12 ? 1 : data["month"] + 1
        );
      }
      if (scrollTop < 100) {
        let firstMonth = this.months[0];
        let { data } = firstMonth;
        await this.setMonth(
          data["month"] == 1 ? data["year"] - 1 : data["year"],
          data["month"] == 1 ? 12 : data["month"] - 1
        );
        this.$refs["scrollMonth"].scrollTo(0, 200);
      }
    },
    /* 防抖 */
    debounce(func, delay) {
      let timer = null;
      return function () {
        if (timer) {
          clearTimeout(timer);
          timer = null;
        }
        timer = setTimeout(() => {
          func(...arguments);
        }, delay);
      };
    },
    changeMonth(type) {
      switch (type) {
        case "add":
          this.setMonth(
            this.date["month"] == 12
              ? this.date["year"] + 1
              : this.date["year"],
            this.date["month"] == 12 ? 1 : this.date["month"] + 1
          );
          break;
        case "reduce":
          this.setMonth(
            this.date["month"] == 1 ? this.date["year"] - 1 : this.date["year"],
            this.date["month"] == 1 ? 12 : this.date["month"] - 1
          );
          break;
      }
    },
    selectCurrentDay(data) {
      // if (data.isGone || data.status == 1) return;
      if (data.status == 1) return;
      this.currentSelectDay = data.date;
    },
    // 获取当前月份和天数
    async getNowMonth() {
      let curDate = new Date();
      // 获取当前月份
      let curYear = curDate.getFullYear();
      let curMonth = curDate.getMonth();
      let curDay = curDate.getDate();
      await this.setMonth(curYear, curMonth);
      await this.changeMonth("add");
      await this.changeMonth("add");
      this.$nextTick(() => {
        console.log("clientHeight", this.$refs["scrollMonth"]);
        this.$refs["scrollMonth"].scrollTo(0, 300);
      });
    },
    // 设置日历月份
    async setMonth(year, month) {
      this.date = {
        ...this.date,
        year,
        month,
      };
      let currentMonthDays = await getMonthDays(year, month);
      let weekNum = await getWeekNum(year, month);
      let monthsDay = setMonthDays(year, month, currentMonthDays, weekNum);
      this.months.push({
        id: `${year}${month}`,
        dateMonth: `${year}-${month}`,
        dateMonthNum: new Date(
          `${year}-${month < 10 ? "0" + month : month}-01`
        ).getTime(),
        data: {
          year,
          month,
        },
        days: this.setDayStatus(monthsDay),
      });
      this.months.sort((a, b) => {
        return a.dateMonthNum - b.dateMonthNum;
      });
      console.log("this.months", this.months);
    },
    setDayStatus(monthDays) {
      let arr = [];
      monthDays.map((dayItem) => {
        dayItem["status"] = this.lessonsList.includes(dayItem["date"]) ? 1 : 4;
        arr.push(dayItem);
      });
      return arr;
    },
    changeDate() {
      this.$emit("changeDate", this.currentSelectDay);
      this.cancel();
    },
    cancel() {
      this.showPopup = false;
      this.currentSelectDay = null;
    },
    async initPopup(course_id) {
      this.course_id = course_id;
      this.months = [];
      this.showPopup = true;
      this.lessonsList = [];
      const res = await request.get(`/lesson/?course_id=${this.course_id}`);
      res.data.map((item) => {
        this.lessonsList.push(item.class_date);
      });
      console.log("getCourse", res);
      this.$nextTick(() => {
        this.getNowMonth();
      });
    },
  },
};
</script>
 
<style lang = "less" scoped>
.calendar {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  height: 100%;
  .calendar_top {
    text-align: center;
    padding: 20px 30px;
    position: relative;
    .calendar_top_title {
      font-size: 32px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 600;
      color: #333333;
      // line-height: 42px;
    }
    .van-icon-cross {
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translate(0, -50%);
    }
  }
  .calendar_week {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 20px 0;
    box-sizing: border-box;
    span {
      font-size: 28px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 36px;
    }
  }
  .calendar_days {
    flex: 1;
    padding: 0 0 20px;
    box-sizing: border-box;
    overflow-y: scroll;
    // scroll-snap-type: y mandatory;
    // scroll-behavior: smooth;
    // &::-webkit-scrollbar {
    //   width: 0;
    // }
    .monthcard {
      /* 元素吸附：子元素设置 */
      /* 属性有 start ; center ; end */
      scroll-snap-align: start;
      /* 表示滚动的时候，要一个一个来 */
      scroll-snap-stop: always;
    }
    .calendar_days_list {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding-bottom: 20px;
    }
    .calendar_days_month {
      text-align: left;
      width: 100%;
      font-size: 36px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      line-height: 50px;
      padding: 10px 20px;
      background: #f8f8f8;
      box-sizing: border-box;
    }
    .calendar_days_item {
      width: 14.285%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px 0 16px;
      position: relative;

      .calendar_days_item_num {
        display: block;
        line-height: 56px;
        text-align: center;
        width: 56px;
        height: 56px;
        border-radius: 50%;
        font-size: 28px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }
      .calendar_days_item_icondrop {
        position: absolute;
        bottom: 0;
        display: block;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: #ffffff;
      }
    }
  }
  .footer_btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 20px 20px;
    background-color: #fff;
    box-shadow: 0px -2px 7px 0px rgba(0, 0, 0, 0.15);
    /deep/.van-button {
      height: 88px;
      border-radius: 12px;
      font-size: 32px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 44px;
      padding: 20px 40px;
      flex: 1;
      background-color: #a5907e;
      color: #fff;
      border-color: #a5907e;
    }
  }
}
</style>