// 计算当前月份的天数
export function getMonthDays(year, month) {
    let newYear = month == 12 ? year + 1 : year;
    let newMonth = month == 12 ? 1 : month;
    let days = new Date(newYear, newMonth, 0).getDate();
    return days;
}
// 计算当前月份1号是星期几
export function getWeekNum(year, month) {
    return new Date(`${year}-${month<10?'0'+month:month}-01`).getDay(); //将返回0，表示星期天
}
// 设置日期参数
export function setMonthDays(year, month, days, weekNum) {
    // 获取当前日期
    let curDate = new Date();
    let curYear = curDate.getFullYear();
    let curMonth = curDate.getMonth();
    let curDay = curDate.getDate();
    let newMonth = month < 10 ? '0' + month : month
    let dateArr = [];
    let currentTime = new Date(`${curYear}-${(curMonth+1)<10?'0'+(curMonth+1):(curMonth+1)}-${curDay<10?'0'+curDay:curDay}`).getTime()
    for (let i = 0; i < days; i++) {
        let newDay = i + 1 < 10 ? '0' + (i + 1) : i + 1
        let dayDate = `${year}-${newMonth}-${newDay}`
        let obj = {
            data: {
                year: year,
                month: month,
                day: i + 1,
            },
            date: dayDate,
            day: i + 1, //值
            status: 4, //状态
            isGone: new Date(dayDate).getTime() <
                currentTime, //是否过期
            left: i == 0 ? 14.285 * weekNum : 0, //每月一号控制css偏差值
        };
        dateArr.push(obj);
    }
    return dateArr;
}